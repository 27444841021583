// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  openid_client_id: '47080F7C93AE8270',
  openid_scope: 'openid%20email%20profile%20groups',
  openid_authorize_endpoint: 'https://portailacces.francetvpub.fr/IdPOAuth2/authorize/ilexoidc',
  api_base_url: 'https://recette-api-parrainage.francetvpub.fr',
  power_bi: 'https://app.powerbi.com/reportEmbed?reportId=03b6edd3-ba03-471f-9139-68621e96b253&groupId=1cc0e445-88d7-4f34-995b-1947' +
    'c641ea94&autoAuth=true&ctid=de8ff668-f63c-40fc-a3d3-330457635a23'
};
